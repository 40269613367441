import React from 'react'
import { Link } from 'gatsby'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import styles from './tos.module.scss'

const Tos = () => {
  return (
    <Layout>
      <SEO title="Terms of Service" />
      <main className={styles.root}>
        <h2>GENERAL</h2>
        <p>
          PicCollage Services include all apps developed by Cardinal Blue
          Software Inc (“We”, “us”). By using any of our services, you are bound
          by these terms. The terms may change from time to time. You can review
          the most current version of the terms here:{' '}
          <Link to="/tos">https://picco.com/tos</Link>.
        </p>
        <p>
          This agreement, and any claims arising out of it, shall be governed
          under the jurisdiction of our choice. PicCollage Services are provided
          “as-is” and assume no responsibility for the failure to store your
          user settings. We are not responsible for modification or loss of your
          content, or for the discontinuation of any of our services. We
          encourage you to keep a copy of your content. From time to time, the
          services may be temporarily down for repairs. We take no
          responsibility for loss of content during these times.
        </p>

        <h2>USER CONDUCT</h2>
        <p>
          You understand that all information, data, text, software, music,
          sound, photographs, graphics, video, messages, tags, or other
          materials ("Content"), whether publicly posted or privately
          transmitted, are the sole responsibility of the person from whom such
          Content originated. This means that you, and not PicCollage, are
          entirely responsible for all Content that you create, save, email,
          transmit or otherwise make available via the PicCollage Services.
          PicCollage does not control the Content posted via the PicCollage
          Services and, as such, does not guarantee the accuracy, integrity or
          quality of such Content. You understand that by using the PicCollage
          Services, you may be exposed to Content that is offensive, indecent or
          objectionable. Under no circumstances will PicCollage be liable in any
          way for any Content, including, but not limited to, any errors or
          omissions in any Content, or any loss or damage of any kind incurred
          as a result of the use of any Content posted, emailed, transmitted or
          otherwise made available via the PicCollage Services.
        </p>

        <p>
          You agree that under the use of PicCollage Services:
          <ul className={styles.nested}>
            <li>
              You must be at least 13 years old or the minimum legal age in your
              country to use PicCollage.
            </li>
            <li>
              You may not use the PicCollage Services for any illegal or
              unauthorized purpose. International users agree to comply with all
              local laws regarding online conduct and acceptable content.
            </li>
            <li>
              You are solely responsible for your conduct and any data, text,
              information, screen names, graphics, photos, profiles, audio and
              video clips, links ("Content") that you create and save using the
              PicCollage Services.
            </li>
            <li>
              You must not modify, adapt or hack PicCollage or modify another
              website so as to falsely imply that it is associated with
              PicCollage.
            </li>
            <li>
              You must not access the PicCollage private API by any other means
              other than the PicCollage application itself.
            </li>
            <li>
              You must not use web URLs in your name without prior written
              consent from PicCollage.
            </li>
            <li>
              You must not transmit any worms or viruses or any code of a
              destructive nature.
            </li>
            <li>
              You must not, in the use of PicCollage, violate any laws in your
              jurisdiction (including but not limited to copyright laws).
            </li>
            <li>
              You must not email, transmit or otherwise make available any
              material that contains software viruses or any other computer
              code, files or programs designed to interrupt, destroy or limit
              the functionality of any computer software or hardware or
              telecommunications equipment.
            </li>
            <li>
              You must not interfere with or disrupt the PicCollage Services or
              servers or networks connected to the PicCollage Services, or
              disobey any requirements, prores, policies or regulations of
              networks connected to the PicCollage Services, including using any
              device, software or routine to bypass our robot exclusion headers.
            </li>
          </ul>
        </p>
        <p>
          In consideration of your use of the PicCollage Services, you represent
          that you are of legal age to form a binding contract and are not a
          person barred from receiving the PicCollage Services under the laws of
          the United States or other applicable jurisdiction.
        </p>

        <h2>GENERAL CONDITIONS</h2>
        <p>
          <ul>
            <li>
              We reserve the right to modify or terminate the PicCollage
              services for any reason, without notice at any time.
            </li>
            <li>
              We reserve the right to alter these Terms of Service at any time.
              If the alterations constitute a material change to the Terms of
              Service, we will notify you via internet mail according to the
              preference expressed on your account. What constitutes a "material
              change" will be determined at our sole discretion, in good faith
              and using common sense and reasonable judgment.
            </li>
            <li>
              We reserve the right to refuse service to anyone for any reason at
              any time. We may, but have no obligation to, remove Content and
              accounts containing Content that we determine in our sole
              discretion are unlawful, offensive, threatening, libelous,
              defamatory, obscene or otherwise objectionable or violates any
              party's intellectual property or these Terms of Services.
            </li>
          </ul>
        </p>

        <h2>PROPRIETARY RIGHTS IN CONTENT ON PICCOLLAGE</h2>
        <p>
          <ul>
            <li>
              PicCollage does NOT claim ANY ownership rights in the text, files,
              images, photos, video, sounds, musical works, works of authorship,
              applications, or any other materials (collectively, "Content")
              that you create using the PicCollage Services.
            </li>
            <li>
              You represent and warrant that: (i) you own the Content created by
              you on or through the PicCollage Services or otherwise have the
              right to grant the license set forth in this section, (ii) the
              creation and use of your Content on or through the PicCollage
              Services does not violate the privacy rights, publicity rights,
              copyrights, contract rights, intellectual property rights or any
              other rights of any person, and (iii) the posting of your Content
              on the Site does not result in a breach of contract between you
              and a third party. You agree to pay for all royalties, fees, and
              any other monies owing any person by reason of Content you create
              on or through the PicCollage Services.
            </li>
            <li>
              The PicCollage Services contain Content of PicCollage ("PicCollage
              Content"). PicCollage Content is protected by copyright,
              trademark, patent, trade secret and other laws, and Cardinal Blue
              owns and retains all rights in the PicCollage Content and the
              PicCollage Services.
            </li>
            <li>
              The PicCollage Services contain Content of Users and other
              PicCollage licensors. Except as provided within this Agreement,
              you may not copy, modify, translate, publish, broadcast, transmit,
              distribute, perform, display, or sell any Content appearing on or
              through the PicCollage Services.
            </li>
            <li>
              PicCollage performs technical functions necessary to offer the
              PicCollage Services, including but not limited to transcoding
              and/or reformatting Content to allow its use throughout the
              PicCollage Services.
            </li>
            <li>
              Although the Site and other PicCollage Services are normally
              available, there will be occasions when the Site or other
              PicCollage Services will be interrupted for scled maintenance or
              upgrades, for emergency repairs, or due to failure of
              telecommunications links and equipment that are beyond the control
              of PicCollage. Also, although PicCollage will normally only delete
              Content that violates this Agreement, PicCollage reserves the
              right to delete any Content for any reason, without prior notice.
              Deleted content may be stored by PicCollage in order to comply
              with certain legal obligations and is not retrievable without a
              valid court order. Consequently, PicCollage encourages you to
              maintain your own backup of your Content. In other words,
              PicCollage is not a backup service. PicCollage will not be liable
              to you for any modification, suspension, or discontinuation of the
              PicCollage Services, or the loss of any Content.
            </li>
          </ul>
        </p>
        <p>
          With respect to Content you submit or make available for inclusion on
          publicly accessible areas of PicCollage, the license to use,
          distribute, reproduce, modify, adapt, publicly perform and publicly
          display such Content on the PicCollage Services solely for the
          purposes of providing and promoting the specific PicCollage to which
          such Content was submitted or made available. This license exists only
          for as long as you elect to continue to include such Content on the
          PicCollage Services and will terminate at the time you remove or
          PicCollage removes such Content from the PicCollage Services.
        </p>

        <h2>CONTRIBUTIONS TO PICCOLLAGE</h2>
        <p>
          By submitting ideas, suggestions, documents, and/or proposals
          ("Contributions") to PicCollage through its suggestion or feedback
          webpages, you acknowledge and agree that: (a) your Contributions do
          not contain confidential or proprietary information; (b) PicCollage is
          not under any obligation of confidentiality, express or implied, with
          respect to the Contributions; (c) PicCollage may have something
          similar to the Contributions already under consideration or in
          development; (d) your Contributions automatically become the property
          of PicCollage without any obligation of PicCollage to you; and (e) you
          are not entitled to any compensation or reimbursement of any kind from
          PicCollage under any circumstances.
        </p>

        <h2>INDEMNITY</h2>
        <p>
          As permitted by law, you agree to indemnify and hold PicCollage and
          its subsidiaries, affiliates, officers, agents, employees, partners
          and licensors harmless from any claim or demand, including reasonable
          attorneys'; fees, made by any third party due to or arising out of
          Content you create, transmit, modify or otherwise make available
          through the PicCollage Services, your use of the PicCollage Services,
          your connection to the PicCollage Services, or your violation of any
          rights of another Terms of Service.
        </p>

        <h2>NO COMMERCIAL REUSE OF PICCOLLAGE SERVICES</h2>
        <p>
          You agree not to reproduce, duplicate, copy, sell, trade, resell or
          exploit for any commercial purposes, any portion or use of, or access
          to, the PicCollage Services.
        </p>

        <h2>MODIFICATIONS TO PICCOLLAGE SERVICES</h2>
        <p>
          PicCollage reserves the right at any time and from time to time to
          modify or discontinue, temporarily or permanently, the PicCollage
          Services (or any part thereof) with or without notice. You agree that
          PicCollage shall not be liable to you or to any third party for any
          modification, suspension or discontinuance of the PicCollage Services
          (or any part thereof).
        </p>

        <h2>TERMINATION</h2>
        <p>
          You agree that PicCollage may, without prior notice, immediately
          terminate, limit your access to or suspend your PicCollage account,
          any associated email address, and access to the PicCollage Services.
          Cause for such termination, limitation of access or suspension shall
          include, but not be limited to, (a) breaches or violations of the TOS
          or other incorporated agreements or guidelines, (b)requests by law
          enforcement or other government agencies, (c) discontinuance or
          material modification to the PicCollage Services (or any part
          thereof), (d) unexpected technical or security issues or problems, (e)
          extended periods of inactivity, (f) engagement by you in fraudulent or
          illegal activities, and/or (g) nonpayment of any fees owed by you in
          connection with the PicCollage Services. Further, you agree that all
          terminations, limitations of access and suspensions for cause shall be
          made in PicCollage's Kids sole discretion and that PicCollage shall
          not be liable to you or any third party for any termination of your
          account, any associated email address, or access to the PicCollage
          Services.
        </p>

        <h2>GDPR COMPLIANCE</h2>
        <p>
          We have implemented our GDPR compliance strategy to make sure the
          rights of our users in the European Union are protected. We are
          committed to giving you transparent information, communication and
          modalities of the information we collect and how we process it and for
          the exercise of your rights under GDPR.
        </p>

        <p>
          Below are the initiatives we have committed to in order to satisfy
          GDPR requirements that apply to our European users:
        </p>

        <h3>User Consent</h3>
        <p>
          Users that reside in the European Union (E.U.) must grant us consent
          before using PicCollage starting from May 25, 2018. By giving us
          consent, you agree to how we collect and process your data.
        </p>

        <p>
          <ol>
            <li>
              Please note you can withdraw your consent at any time on the app’s
              Data Privacy page, accessible through the Settings page. If you
              have created a social network account within PicCollage, you have
              to go to our webpage to edit your account preferences in order to
              deactivate your account.
            </li>

            <li>
              You must be at least 13 years old to create a social network
              account within the PicCollage app, but you can be younger to use
              PicCollage.
            </li>

            <li>
              For users residing in the European Union, please note you can only
              create an account if you are over the age of 16. Otherwise, you
              must receive explicit consent from a parental guardian in order to
              create an account.
            </li>
          </ol>
        </p>

        <h3>Data Subject Rights</h3>
        <p>
          As the data subject, you have the right to either give consent or
          decline consent to our terms and policies. Please note by declining
          consent, you will not be able to use PicCollage at the moment. The
          following are the rights you can exercise regarding your information
          under GDPR:
        </p>

        <p>
          <ol>
            <li>
              The right of access
              <br />
              As the data subject, you have the right to know how your data is
              being processed and you can also request to access your data.
            </li>

            <li>
              The right to rectification
              <br />
              As the data subject, you can obtain rectification of inaccurate
              personal data from us in a timely manner.
            </li>

            <li>
              The right to erasure
              <br />
              As the data subject, you have the right to obtain the erasure of
              your data under the following circumstances:
              <ul className={styles.nested}>
                <li>
                  the personal data are no longer necessary (for example, in the
                  case that a user deletes their account)
                </li>

                <li>when you withdraw consent to our terms and policies</li>

                <li>when your personal data have been unlawfully processed</li>
              </ul>
            </li>

            <li>
              The right to restriction of processing
              <br />
              You have the right to obtain restriction of our processing of your
              data when the accuracy of the data is contested by you, the data
              subject.
            </li>

            <li>
              The right to be informed
              <br />
              You have the right to be informed by PicCollage on the changes we
              make regarding the collection and processing of your information.
            </li>

            <li>
              The right to object
              <br />
              As the data subject, you have the right to ask us to stop
              processing your data.
            </li>

            <li>
              The right not to be subject to automated individual
              decision-making, including profiling.
            </li>
          </ol>
        </p>

        <h2>CCPA COMPLIANCE</h2>
        <p>
          We have implemented our CCPA compliance strategy for Californian
          residents. We are committed to giving you transparent information,
          communication and modalities of the information we collect and how we
          process it and for the exercise of your rights under CCPA.
        </p>
        <p>
          Below are the initiatives we have committed to in order to satisfy
          CCPA requirements that apply to our Californian residents:
        </p>

        <h3>User Consent</h3>
        <p>
          Users that reside in California have the right to revoke consent at
          any time starting from January 1, 2020. By giving us consent, you
          agree to how we collect and process your data.
        </p>
        <p>
          <ul>
            <li>
              Please note you can withdraw your consent at any time. To make
              this request, please email support@cardinalblue.com or contact us
              using this address: Cardinal Blue Software, P.O. Box 390677,
              Mountain View, CA 94039. Please note that revoking your consent
              will require discontinuing use of the app at this time.
            </li>
            <li>
              To delete a PicCollage Social account, please login to your
              account from piccollage.com and choose “Delete account” on the
              settings page.
            </li>
            <li>
              You must be at least 13 years old to create a social network
              account within the PicCollage app, but you can be younger to use
              PicCollage.
            </li>
          </ul>
        </p>

        <h3>Data Subject Rights</h3>
        <p>
          As the data subject, you have the right to either give consent or
          decline consent to our terms and policies. Please note by declining
          consent, you will not be able to use PicCollage at the moment. The
          following are the rights you can exercise regarding your information
          under CCPA:
        </p>
        <p>
          <ul>
            <li>
              The right of access
              <br /> As the data subject, you have the right to know how your
              data is being processed and you can also request to access your
              data.
            </li>
            <li>
              The right to rectification <br /> As the data subject, you can
              obtain rectification of inaccurate personal data from us in a
              timely manner.
            </li>
            <li>
              The right to deletion
              <br />
              As the data subject, you have the right to obtain the deletion of
              your data under the following circumstances:
              <ul className={styles.nested}>
                <li>
                  the personal data are no longer necessary (for example, in the
                  case that a user deletes their account)
                </li>
                <li>when you withdraw consent to our terms and policies</li>
                <li>when your personal data have been unlawfully processed</li>
              </ul>
            </li>
            <li>
              The right to restriction of processing <br /> You have the right
              to obtain restriction of our processing of your data when the
              accuracy of the data is contested by you, the data subject.
            </li>
            <li>
              The right to be informed <br /> You have the right to be informed
              by PicCollage on the changes we make regarding the collection and
              processing of your information.
            </li>
            <li>
              The right to object <br /> As the data subject, you have the right
              to ask us to stop processing your data.
            </li>
            <li>
              The right not to be subject to automated individual
              decision-making, including profiling.
            </li>
          </ul>
        </p>

        <h2>DISCLAIMER OF WARRANTIES</h2>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
          <ul className={styles.nested}>
            <li>
              YOUR USE OF THE PICCOLLAGE SERVICES AND SOFTWARE ARE AT YOUR SOLE
              RISK. THE PICCOLLAGE SERVICES AND SOFTWARE ARE PROVIDED ON AN "AS
              IS" AND "AS AVAILABLE" BASIS. PICCOLLAGE AND ITS SUBSIDIARIES,
              AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS
              EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
              IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
              TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
              NON-INFRINGEMENT.
            </li>
            <li>
              PICCOLLAGE AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES,
              AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (i) THE
              PICCOLLAGE SERVICES OR SOFTWARE WILL MEET YOUR REQUIREMENTS; (ii)
              THE PICCOLLAGE SERVICES OR SOFTWARE WILL BE UNINTERRUPTED, TIMELY,
              SECURE OR ERROR-FREE; (iii) THE RESULTS THAT MAY BE OBTAINED FROM
              THE USE OF THE PICCOLLAGE SERVICES OR SOFTWARE WILL BE ACCURATE OR
              RELIABLE; (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION
              OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE
              PICCOLLAGE SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS; AND
              (v) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.
            </li>
            <li>
              ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
              THE PICCOLLAGE SERVICES OR SOFTWARE IS ACCESSED AT YOUR OWN
              DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND
              HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT
              TO ANY DAMAGE TO YOUR COMPUTER SYSTEM, INTERNET ACCESS, DOWNLOAD
              OR DISPLAY DEVICE, OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD
              OF ANY SUCH MATERIAL.
            </li>
            <li>
              NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
              FROM PICCOLLAGE OR THROUGH OR FROM THE PICCOLLAGE SERVICES OR
              SOFTWARE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE
              TOS.
            </li>
            <li>
              A SMALL PERCENTAGE OF USERS MAY EXPERIENCE EPILEPTIC SEIZURES WHEN
              EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS ON A COMPUTER
              SCREEN OR WHILE USING THE PICCOLLAGE SERVICE. CERTAIN CONDITIONS
              MAY INDUCE PREVIOUSLY UNDETECTED EPILEPTIC SYMPTOMS EVEN IN USERS
              WHO HAVE NO HISTORY OF PRIOR SEIZURES OR EPILEPSY. IF YOU, OR
              ANYONE IN YOUR FAMILY, HAVE AN EPILEPTIC CONDITION, CONSULT YOUR
              PHYSICIAN PRIOR TO USING THE PICCOLLAGE SERVICE. IMMEDIATELY
              DISCONTINUE USE OF THE PICCOLLAGE SERVICES AND CONSULT YOUR
              PHYSICIAN IF YOU EXPERIENCE ANY OF THE FOLLOWING SYMPTOMS WHILE
              USING THE PICCOLLAGE SERVICE: DIZZINESS, ALTERED VISION, EYE OR
              MUSCLE TWITCHES, LOSS OF AWARENESS, DISORIENTATION, ANY
              INVOLUNTARY MOVEMENT, OR CONVULSIONS.
            </li>
          </ul>
        </p>

        <h2>LIMITATION OF LIABILITY</h2>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT PICCOLLAGE AND ITS
          SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND
          LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING,
          BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA
          OR OTHER INTANGIBLE LOSSES (EVEN IF PICCOLLAGE HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (a) THE USE OR THE
          INABILITY TO USE THE PICCOLLAGE SERVICE; (b) THE COST OF PROCUREMENT
          OF SUBSTITUTE GOODS AND SERVICES; (c) UNAUTHORIZED ACCESS TO OR
          ALTERATION OF YOUR TRANSMISSIONS OR DATA; (d) STATEMENTS OR CONDUCT OF
          ANY THIRD PARTY ON THE PICCOLLAGE SERVICE; OR (e) ANY OTHER MATTER
          RELATING TO THE PICCOLLAGE SERVICE.
        </p>

        <h2>NO THIRD-PARTY BENEFICIARIES</h2>
        <p>
          You agree that there shall be no third-party beneficiaries to this
          agreement.
        </p>

        <h2>NOTICE</h2>
        <p>
          PicCollage may provide you with notices, including those regarding
          changes to the TOS, including by but not limited to email, regular
          mail, postings on the PicCollage Services, or other reasonable means
          now known or hereafter developed. Such notices may not be received if
          you violate this TOS by accessing the PicCollage Services in an
          unauthorized manner. Your agreement to this TOS constitutes your
          agreement that you are deemed to have received any and all notices
          that would have been delivered had you accessed the PicCollage
          Services in an authorized manner.
        </p>

        <h2>TRADEMARK INFORMATION</h2>
        <p>
          You agree that all of PicCollage’ trademarks, trade names, service
          marks and other PicCollage logos and brand features, and product and
          service names are trademarks and the property of Cardinal Blue
          Software, Inc. (the "PicCollage Marks"). Without prior permission, you
          agree not to display or use in any manner the PicCollage Marks.
        </p>

        <h2>
          NOTICE AND PRORE FOR MAKING CLAIMS OF COPYRIGHT OR INTELLECTUAL
          PROPERTY INFRINGEMENT
        </h2>
        <p>
          PicCollage respects the intellectual property of others, and we ask
          our users to do the same. PicCollage may, in appropriate circumstances
          and at its discretion, disable and/or terminate the accounts of users
          who may be repeat infringers.
        </p>

        <h2>VIOLATIONS</h2>
        <p>Please report any violations of the TOS to us.</p>

        <br />
        <p>Last updated: August 2021</p>
      </main>
    </Layout>
  )
}

export default Tos
